import lookupAPI from '@/api/lookup'
import { replaceAllProperties } from '@/utils/utils.js'

function sortLookup(lookup) {
  // Sort by ranking then alphabetical
  lookup.sort((a, b) => {
    if (a.ranking === b.ranking) {
      return a.displayName.localeCompare(b.displayName)
    } else {
      return a.ranking > 0 ? a.ranking - b.ranking : b.ranking - a.ranking
    }
  })
  // Add value & label
  lookup.forEach((item) => {
    item.value = item.name
    item.label = item.displayName
  })
  return lookup
}

const state = {
  // Remote
  grain: [],
  farmStorage: [],
  firmOfferExpiry: [],
  paymentTerm: [],
  season: [],
  states: [],
  warehouseLocation: [],
  offerWithdrawOptions: [],

  // In app
  storageType: [
    { value: 'ON_FARM', label: 'On Farm' },
    { value: 'WAREHOUSE', label: 'Warehouse' },
  ],
  pricingMethod: [
    { value: 'FREE_ON_TRUCK', label: 'Free on truck' },
    { value: 'WAREHOUSE_TRANSFER', label: 'Warehouse transfer' },
    { value: 'EX_FARM', label: 'Ex farm' },
  ],
  offerType: [
    { value: 'INDICATIVE', label: 'Indicative' },
    { value: 'FIRM', label: 'Firm' },
  ],
  orgTypes: [
    { value: 'COMPANY', label: 'Company (Pty Ltd)' },
    { value: 'PARTNERSHIP', label: 'Partnership' },
    { value: 'SOLE_TRADER', label: 'Sole trader' },
    { value: 'TRUST', label: 'Trust' },
  ],
  transferType: [
    { value: 'PICKUP', label: 'Picked up' },
    { value: 'DELIVERED', label: 'Delivered' },
  ],

  directionOptions: [
    { value: 'N', label: 'N' },
    { value: 'NE', label: 'NE' },
    { value: 'E', label: 'E' },
    { value: 'SE', label: 'SE' },
    { value: 'S', label: 'S' },
    { value: 'SW', label: 'SW' },
    { value: 'W', label: 'W' },
    { value: 'NW', label: 'NW' }
  ],


  // Site
  phone: '1300447246',
  phoneFormatted: '1300 447 246',

  // Admin
  adminGrain: [],
  adminFarmStorage: [],
  adminFirmOfferExpiry: [],
  adminPaymentTerm: [],
  adminSeason: [],
  adminStates: [],
  adminWarehouseLocation: [],
}

const getters = {
  grades: (state) => (grainName) => {
    const filteredGrain = state.grain.filter((item) => item.name === grainName)
    if (filteredGrain.length > 0) return filteredGrain[0].grades
    return []
  },
  regions: (state) => (stateName) => {
    const filteredStates = state.states.filter(
      (item) => item.name === stateName,
    )
    if (filteredStates.length > 0) return filteredStates[0].regions
    return []
  },
  warehouseProviders: (state) => {
    return [
      ...new Map(
        state.adminWarehouseLocation.map((item) => [
          item.warehouseProvider,
          { value: item.warehouseProvider, label: item.warehouseProviderName },
        ]),
      ).values(),
    ]
  }
}

const mutations = {
  SET_GRAIN(state, value) {
    state.grain = sortLookup(value)
    state.grain.forEach((item) => {
      if (Array.isArray(item.grades)) sortLookup(item.grades)
    })
  },
  SET_FARM_STORAGE(state, value) {
    state.farmStorage = sortLookup(value)
  },
  SET_FIRM_OFFER_EXPIRY(state, value) {
    state.firmOfferExpiry = sortLookup(value)
  },
  SET_PAYMENT_TERM(state, value) {
    state.paymentTerm = sortLookup(value)
  },
  SET_SEASON(state, value) {
    state.season = sortLookup(value)
  },
  SET_WAREHOUSE_LOCATION(state, value) {
    state.warehouseLocation = sortLookup(value)
  },
  SET_STATES(state, value) {
    state.states = sortLookup(value)
    state.states.forEach((item) => {
      if (Array.isArray(item.regions)) sortLookup(item.regions)
    })
  },
  SET_OFFER_WITHDRAW_OPTIONS(state, value) {
    state.offerWithdrawOptions = sortLookup(value)
  },


  // Grain
  SET_ADMIN_GRAIN(state, value) {
    state.adminGrain = sortLookup(value)
    state.adminGrain.forEach((item) => {
      if (Array.isArray(item.grades)) sortLookup(item.grades)
    })
  },
  ADD_ADMIN_GRAIN(state, value) {
    state.adminGrain.push(value)
    state.adminGrain = sortLookup(state.adminGrain)
  },
  UPDATE_ADMIN_GRAIN(state, value) {
    const grain = state.adminGrain.filter((item) => item.name === value.name)[0]
    replaceAllProperties(grain, value)
  },

  // Grade
  ADD_ADMIN_GRADE(state, value) {
    const grain = state.adminGrain.filter(
      (item) => item.name === value.grainName,
    )[0]
    grain.grades.push(value)
    sortLookup(grain.grades)
  },
  UPDATE_ADMIN_GRADE(state, value) {
    const grain = state.adminGrain.filter(
      (item) => item.name === value.grainName,
    )[0]
    const grade = grain.grades.filter((item) => item.name === value.name)[0]
    replaceAllProperties(grade, value)
  },

  // Warehouse location
  SET_ADMIN_WAREHOUSE_LOCATION(state, value) {
    state.adminWarehouseLocation = value
  },
  ADD_ADMIN_WAREHOUSE_LOCATION(state, value) {
    state.adminWarehouseLocation.push(value)
    sortLookup(state.adminWarehouseLocation)
  },
  UPDATE_ADMIN_WAREHOUSE_LOCATION(state, value) {
    const valueToUpdate = state.adminWarehouseLocation.filter(
      (item) => item.name === value.name,
    )[0]
    replaceAllProperties(valueToUpdate, value)
  },

  // Farm storage
  SET_ADMIN_FARM_STORAGE(state, value) {
    state.adminFarmStorage = value
  },
  ADD_ADMIN_FARM_STORAGE(state, value) {
    state.adminFarmStorage.push(value)
    sortLookup(state.adminFarmStorage)
  },
  UPDATE_ADMIN_FARM_STORAGE(state, value) {
    const valueToUpdate = state.adminFarmStorage.filter(
      (item) => item.name === value.name,
    )[0]
    replaceAllProperties(valueToUpdate, value)
  },

  // Firm offer expiry
  SET_ADMIN_FIRM_OFFER_EXPIRY(state, value) {
    state.adminFirmOfferExpiry = value
  },
  ADD_ADMIN_FIRM_OFFER_EXPIRY(state, value) {
    state.adminFirmOfferExpiry.push(value)
    sortLookup(state.adminFirmOfferExpiry)
  },
  UPDATE_ADMIN_FIRM_OFFER_EXPIRY(state, value) {
    const valueToUpdate = state.adminFirmOfferExpiry.filter(
      (item) => item.name === value.name,
    )[0]
    replaceAllProperties(valueToUpdate, value)
  },

  // Payment term
  SET_ADMIN_PAYMENT_TERM(state, value) {
    state.adminPaymentTerm = value
  },
  ADD_ADMIN_PAYMENT_TERM(state, value) {
    state.adminPaymentTerm.push(value)
    sortLookup(state.adminPaymentTerm)
  },
  UPDATE_ADMIN_PAYMENT_TERM(state, value) {
    const valueToUpdate = state.adminPaymentTerm.filter(
      (item) => item.name === value.name,
    )[0]
    replaceAllProperties(valueToUpdate, value)
  },

  // Season
  SET_ADMIN_SEASON(state, value) {
    state.adminSeason = value
  },
  ADD_ADMIN_SEASON(state, value) {
    state.adminSeason.push(value)
    sortLookup(state.adminSeason)
  },
  UPDATE_ADMIN_SEASON(state, value) {
    const valueToUpdate = state.adminSeason.filter(
      (item) => item.name === value.name,
    )[0]
    replaceAllProperties(valueToUpdate, value)
  },

  // States
  SET_ADMIN_STATES(state, value) {
    state.adminStates = value
  },
  ADD_ADMIN_STATES(state, value) {
    state.adminStates.push(value)
    sortLookup(state.adminStates)
  },
  UPDATE_ADMIN_STATES(state, value) {
    const valueToUpdate = state.adminStates.filter(
      (item) => item.name === value.name,
    )[0]
    replaceAllProperties(valueToUpdate, value)
  },
}

const actions = {
  async getLookups({ commit }) {
    const result = await lookupAPI.getLookups()
    commit('SET_GRAIN', result?.grain)
    commit('SET_FARM_STORAGE', result?.farmStorage)
    commit('SET_FIRM_OFFER_EXPIRY', result?.firmOfferExpiry)
    commit('SET_PAYMENT_TERM', result?.paymentTerm)
    commit('SET_WAREHOUSE_LOCATION', result?.warehouseLocation)
    commit('SET_SEASON', result?.season)
    commit('SET_STATES', result?.state)
    commit('SET_OFFER_WITHDRAW_OPTIONS', result?.offerWithdrawOptions)
  },

  // Grain
  async getGrain({ commit }) {
    const result = await lookupAPI.getGrain()
    commit('SET_ADMIN_GRAIN', result.data)
  },
  async addGrain({ commit }, grain) {
    const result = await lookupAPI.addGrain(grain)
    commit('ADD_ADMIN_GRAIN', result.data)
  },
  async updateGrain({ commit }, grain) {
    const result = await lookupAPI.updateGrain(grain)
    commit('UPDATE_ADMIN_GRAIN', result.data)
  },

  // Grade
  async addGrade({ commit }, grade) {
    const result = await lookupAPI.addGrade(grade)
    commit('ADD_ADMIN_GRADE', result.data)
  },
  async updateGrade({ commit }, grade) {
    const result = await lookupAPI.updateGrade(grade)
    commit('UPDATE_ADMIN_GRADE', result.data)
  },

  // Warehouse location
  async getWarehouseLocation({ commit }) {
    const result = await lookupAPI.getWarehouseLocation()
    commit('SET_ADMIN_WAREHOUSE_LOCATION', result.data)
  },
  async addWarehouseLocation({ commit }, value) {
    const result = await lookupAPI.addWarehouseLocation(value)
    commit('ADD_ADMIN_WAREHOUSE_LOCATION', result.data)
  },
  async updateWarehouseLocation({ commit }, value) {
    const result = await lookupAPI.updateWarehouseLocation(value)
    commit('UPDATE_ADMIN_WAREHOUSE_LOCATION', result.data)
  },

  // Farm storage
  async getFarmStorage({ commit }) {
    const result = await lookupAPI.getFarmStorage()
    commit('SET_ADMIN_FARM_STORAGE', result.data)
  },
  async addFarmStorage({ commit }, value) {
    const result = await lookupAPI.addFarmStorage(value)
    commit('ADD_ADMIN_FARM_STORAGE', result.data)
  },
  async updateFarmStorage({ commit }, value) {
    const result = await lookupAPI.updateFarmStorage(value)
    commit('UPDATE_ADMIN_FARM_STORAGE', result.data)
  },

  // Firm offer expiry
  async getFirmOfferExpiry({ commit }) {
    const result = await lookupAPI.getFirmOfferExpiry()
    commit('SET_ADMIN_FIRM_OFFER_EXPIRY', result.data)
  },
  async addFirmOfferExpiry({ commit }, value) {
    const result = await lookupAPI.addFirmOfferExpiry(value)
    commit('ADD_ADMIN_FIRM_OFFER_EXPIRY', result.data)
  },
  async updateFirmOfferExpiry({ commit }, value) {
    const result = await lookupAPI.updateFirmOfferExpiry(value)
    commit('UPDATE_ADMIN_FIRM_OFFER_EXPIRY', result.data)
  },

  // Payment term
  async getPaymentTerm({ commit }) {
    const result = await lookupAPI.getPaymentTerm()
    commit('SET_ADMIN_PAYMENT_TERM', result.data)
  },
  async addPaymentTerm({ commit }, value) {
    const result = await lookupAPI.addPaymentTerm(value)
    commit('ADD_ADMIN_PAYMENT_TERM', result.data)
  },
  async updatePaymentTerm({ commit }, value) {
    const result = await lookupAPI.updatePaymentTerm(value)
    commit('UPDATE_ADMIN_PAYMENT_TERM', result.data)
  },

  // Season
  async getSeason({ commit }) {
    const result = await lookupAPI.getSeason()
    commit('SET_ADMIN_SEASON', result.data)
  },
  async addSeason({ commit }, value) {
    const result = await lookupAPI.addSeason(value)
    commit('ADD_ADMIN_SEASON', result.data)
  },
  async updateSeason({ commit }, value) {
    const result = await lookupAPI.updateSeason(value)
    commit('UPDATE_ADMIN_SEASON', result.data)
  },

  // States
  async getStates({ commit }) {
    const result = await lookupAPI.getStates()
    commit('SET_ADMIN_STATES', result.data)
  },
  async addStates({ commit }, value) {
    const result = await lookupAPI.addStates(value)
    commit('ADD_ADMIN_STATES', result.data)
  },
  async updateStates({ commit }, value) {
    const result = await lookupAPI.updateStates(value)
    commit('UPDATE_ADMIN_STATES', result.data)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
